import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {

    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {

    }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            // new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/app/admin/hostDashboard'),
            new AppMenuItem('Map View', 'Pages.Sites', 'flaticon-earth-globe', '/app/main/mapview'),
            new AppMenuItem('Site Dashboard', 'Pages.Sites', 'flaticon-line-graph', '/app/main/dashboard'),
            new AppMenuItem('Alarms', 'Pages.Sites', 'flaticon-warning', '/app/main/alarms'),
            //new AppMenuItem('Alarms', 'Pages.Alarming', 'flaticon-warning', '/app/main/alarms'),
            new AppMenuItem('Battery Maintenance', 'Pages.Sites', 'flaticon-light', '/app/main/batteries'),
            new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),            

            new AppMenuItem('Site Configuration', 'Pages.Sites', 'flaticon-location', '/app/main/sites'),
            new AppMenuItem('Group Configuration', 'Pages.Groups', 'flaticon-network', '/app/main/groups'),

            //new AppMenuItem('Jobs', 'Pages.Jobs', 'flaticon-stopwatch', '/app/main/jobs'),

            new AppMenuItem('Reports', '', 'flaticon-diagram', '', [
                new AppMenuItem('Generic Report', 'Pages.Groups', 'flaticon-line-graph', '/app/main/generic-report'),
                new AppMenuItem('Site Report', 'Pages.Groups', 'flaticon-map-location', '/app/main/site-report'),
                new AppMenuItem('Group Report', 'Pages.Groups', 'flaticon-map', '/app/main/group-report'),
                new AppMenuItem('Measurement Report', 'Pages.Groups', 'flaticon-clock-1', '/app/main/measurement-report')
            ]),
            //new AppMenuItem('Utility Test', 'Pages.Sites', 'flaticon-stopwatch', '/app/main/utilityTest'),
             new AppMenuItem('Administration', '', 'flaticon-interface-8', '', [                
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                new AppMenuItem('Manage Customers', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
                new AppMenuItem('Manage Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),           
                new AppMenuItem('MasterDevices', 'Pages.MasterDevices', 'flaticon-computer', '/app/admin/Devices'),
                new AppMenuItem('MasterRTUs', 'Pages.Administration.MasterRTUs', 'flaticon-car', '/app/admin/RTUs'),
                new AppMenuItem('MasterMeters', 'Pages.Administration.MasterMeters', 'flaticon-stopwatch', '/app/admin/Meters'),
                //new AppMenuItem('MasterFormulas', 'Pages.Administration.MasterFormulas', 'flaticon-analytics', '/app/admin/masterFormulas'),

                // new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),          
                // new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages'),
                new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
                new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                // new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings')
            ]),
            new AppMenuItem('Help', '', 'flaticon-information', '/app/main/help'),
            // new AppMenuItem('DemoUiComponents', 'Pages.DemoUiComponents', 'flaticon-shapes', '/app/admin/demo-ui-components')
            
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null || subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            } else if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach(menuItem => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach(subMenu => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
