import { Component, EventEmitter, Injector, Output, ViewChild, OnInit } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import {
    CurrentUserProfileEditDto,
    SettingScopes,
    ProfileServiceProxy,
    UpdateGoogleAuthenticatorKeyOutput,
    SendVerificationSmsInputDto,
    UserAdditionalPhonesServiceProxy,
    UserAdditionalPhoneDto,
    UserAdditionalPhoneInline
} from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap';
import { SmsVerificationModalComponent } from './sms-verification-modal.component';
import { finalize } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { Table } from 'primeng/table';

@Component({
    selector: 'mySettingsModal',
    templateUrl: './my-settings-modal.component.html'
})
export class MySettingsModalComponent extends AppComponentBase implements OnInit {

    @ViewChild('mySettingsModal', { static: true }) modal: ModalDirective;
    @ViewChild('smsVerificationModal', { static: false }) smsVerificationModal: SmsVerificationModalComponent;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    //@ViewChild('dataTable', { static: true }) dataTable: Table;

    public active = false;
    public saving = false;
    public isGoogleAuthenticatorEnabled = false;
    public isPhoneNumberConfirmed: boolean;
    public smsEnabled: boolean;
    public user: CurrentUserProfileEditDto;
    public showTimezoneSelection: boolean = abp.clock.provider.supportsMultipleTimezone;
    public canChangeUserName: boolean;
    public defaultTimezoneScope: SettingScopes = SettingScopes.User;
    private _initialTimezone: string = undefined;
    public savedPhoneNumber: string;
    public newPhoneNumber: string;
    isMultiTenancyEnabled: boolean = this.multiTenancy.isEnabled;
    isTwoFactorLoginEnabledForApplication = false;
    separator:string = "/";
    mdTwoDigits: string = "N";
    dateFormatDataSource:any = [];
    hTwoDigits: string = "N";
    displaySec: string = "Y";

    constructor(
        injector: Injector,
        private _profileService: ProfileServiceProxy,
        private _userAdditionalPhonesServiceProxy : UserAdditionalPhonesServiceProxy
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.isTwoFactorLoginEnabledForApplication =
            abp.setting.getBoolean('Abp.Zero.UserManagement.TwoFactorLogin.IsEnabled');
    }

    

    show(): void {
        this.active = true;
        this._profileService.getCurrentUserProfileForEdit().subscribe((result) => {
            this.smsEnabled = this.setting.getBoolean('App.UserManagement.SmsVerificationEnabled');
            this.user = result;
            this._initialTimezone = result.timezone;
            this.canChangeUserName = this.user.userName !== AppConsts.userManagement.defaultAdminUserName;
            this.getPhoneNumbers();
            this.modal.show();
            this.isGoogleAuthenticatorEnabled = result.isGoogleAuthenticatorEnabled;
            this.isPhoneNumberConfirmed = result.isPhoneNumberConfirmed;
            this.savedPhoneNumber = result.phoneNumber;

            if(this.user.dateFormat == ""|| this.user.dateFormat == null){
                this.separator = "/";
                this.mdTwoDigits = "N";

                this.dateFormatDataSource.push({id:"M/d/yyyy", name:"M/d/yyyy"});
                this.dateFormatDataSource.push({id:"d/M/yyyy", name:"d/M/yyyy"});
                this.dateFormatDataSource.push({id:"yyyy/M/d", name:"yyyy/M/d"});
            }
            else{
                if(this.user.dateFormat.indexOf("/") > 0)
                    this.separator = "/";
                else
                    this.separator = "-";

                let dateFormatArray = this.user.dateFormat.split(this.separator);
                if(dateFormatArray[1].length == 1)
                    this.mdTwoDigits = "N";
                else
                    this.mdTwoDigits = "Y";           
                    
                this.dateFormatDataSource = [];
                if( this.mdTwoDigits == "Y"){
                    this.dateFormatDataSource.push({id:"MM" + this.separator + "dd" + this.separator + "yyyy", name:"MM" + this.separator + "dd" + this.separator + "yyyy"});
                    this.dateFormatDataSource.push({id:"dd" + this.separator + "MM" + this.separator + "yyyy", name:"dd" + this.separator + "MM" + this.separator + "yyyy"});
                    this.dateFormatDataSource.push({id:"yyyy" + this.separator + "MM" + this.separator + "dd", name:"yyyy" + this.separator + "MM" + this.separator + "dd"});
                }
                else{
                    this.dateFormatDataSource.push({id:"M" + this.separator + "d" + this.separator + "yyyy", name:"M" + this.separator + "d" + this.separator + "yyyy"});
                    this.dateFormatDataSource.push({id:"d" + this.separator + "M" + this.separator + "yyyy", name:"d" + this.separator + "M" + this.separator + "yyyy"});
                    this.dateFormatDataSource.push({id:"yyyy" + this.separator + "M" + this.separator + "d", name:"yyyy" + this.separator + "M" + this.separator + "d"});
                }
            }

            if(this.user.timeFormat == ""|| this.user.timeFormat == null){
                this.hTwoDigits = "N";
                this.displaySec = "Y";
            }
            else{
                let timeFormatArray = this.user.timeFormat.split(":");
                if(timeFormatArray.length == 3)
                    this.displaySec = "Y";
                else
                    this.displaySec = "N";

                if(timeFormatArray[0].length == 2)
                    this.hTwoDigits = "Y";
                else
                    this.hTwoDigits = "N";
            }

            if(this.user.timeClock== ""|| this.user.timeClock == null)
                this.user.timeClock = "12";
        });
    }

    onSeparatorChange($event): void {
        this.dateFormatDataSource = [];
        if(this.mdTwoDigits == "Y"){
            this.dateFormatDataSource.push({id:"MM" + $event + "dd" + $event + "yyyy", name:"MM" + $event + "dd" + $event + "yyyy"});
            this.dateFormatDataSource.push({id:"dd" + $event + "MM" + $event + "yyyy", name:"dd" + $event + "MM" + $event + "yyyy"});
            this.dateFormatDataSource.push({id:"yyyy" + $event + "MM" + $event + "dd", name:"yyyy" + $event + "MM" + $event + "dd"});
        }
        else{
            this.dateFormatDataSource.push({id:"M" + $event + "d" + $event + "yyyy", name:"M" + $event + "d" + $event + "yyyy"});
            this.dateFormatDataSource.push({id:"d" + $event + "M" + $event + "yyyy", name:"d" + $event + "M" + $event + "yyyy"});
            this.dateFormatDataSource.push({id:"yyyy" + $event + "M" + $event + "d", name:"yyyy" + $event + "M" + $event + "d"});
        }
    }

    onMDTwoDigitsChange($event):void {
        this.dateFormatDataSource = [];
        if($event == "Y"){
            this.dateFormatDataSource.push({id:"MM" + this.separator + "dd" + this.separator + "yyyy", name:"MM" + this.separator + "dd" + this.separator + "yyyy"});
            this.dateFormatDataSource.push({id:"dd" + this.separator + "MM" + this.separator + "yyyy", name:"dd" + this.separator + "MM" + this.separator + "yyyy"});
            this.dateFormatDataSource.push({id:"yyyy" + this.separator + "MM" + this.separator + "dd", name:"yyyy" + this.separator + "MM" + this.separator + "dd"});
        }
        else{
            this.dateFormatDataSource.push({id:"M" + this.separator + "d" + this.separator + "yyyy", name:"M" + this.separator + "d" + this.separator + "yyyy"});
            this.dateFormatDataSource.push({id:"d" + this.separator + "M" + this.separator + "yyyy", name:"d" + this.separator + "M" + this.separator + "yyyy"});
            this.dateFormatDataSource.push({id:"yyyy" + this.separator + "M" + this.separator + "d", name:"yyyy" + this.separator + "M" + this.separator + "d"});
        }
    }

    updateQrCodeSetupImageUrl(): void {
        this._profileService.updateGoogleAuthenticatorKey().subscribe((result: UpdateGoogleAuthenticatorKeyOutput) => {
            this.user.qrCodeSetupImageUrl = result.qrCodeSetupImageUrl;
            this.isGoogleAuthenticatorEnabled = true;
        });
    }

    disableGoogleAuthenticator(): void {
        this._profileService.disableGoogleAuthenticator().subscribe(() => {
            this.isGoogleAuthenticatorEnabled = false;
        });
    }

    smsVerify(): void {
        let input = new SendVerificationSmsInputDto();
        input.phoneNumber = this.user.phoneNumber;
        this._profileService.sendVerificationSms(input)
            .subscribe(() => {
                this.smsVerificationModal.show();
            });
    }

    changePhoneNumberToVerified(): void {
        this.isPhoneNumberConfirmed = true;
        this.savedPhoneNumber = this.user.phoneNumber;
    }

    onShown(): void {
        document.getElementById('Name').focus();
    }

    close(): void {
        this.active = false;
        this.modal.hide();
    }

    save(): void {
        this.saving = true;
        this.GetTimeFormat();
        this._profileService.updateCurrentUserProfile(this.user)
            .pipe(finalize(() => { this.saving = false; }))
            .subscribe(() => {
                this.appSession.user.name = this.user.name;
                this.appSession.user.surname = this.user.surname;
                this.appSession.user.userName = this.user.userName;
                this.appSession.user.emailAddress = this.user.emailAddress;
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);

                if (abp.clock.provider.supportsMultipleTimezone && this._initialTimezone !== this.user.timezone) {
                    this.message.info(this.l('TimeZoneSettingChangedRefreshPageNotification')).then(() => {
                        window.location.reload();
                    });
                }
            });
    }

    GetTimeFormat(): void{
        if(this.hTwoDigits == "Y" && this.displaySec == "Y")
            this.user.timeFormat = "HH:MM:SS";
        else if(this.hTwoDigits == "Y" && this.displaySec == "N")
            this.user.timeFormat = "HH:MM";
        else if(this.hTwoDigits == "N" && this.displaySec == "Y")
            this.user.timeFormat = "H:MM:SS";
        else
            this.user.timeFormat = "H:MM";
    }


    //Phone numbers

    getPhoneNumbers(){
        //this.primengTableHelper.showLoadingIndicator();

        //this._userAdditionalPhonesServiceProxy.getAll(this.appSession.userId, this.primengTableHelper.getSorting(this.dataTable),0,1000).subscribe(result => {
        //    this.primengTableHelper.records = result.items;
            
        //    this.primengTableHelper.hideLoadingIndicator();
        //});
    }

    editPhoneNumber(userAdditionalPhoneInline : UserAdditionalPhoneInline) {
        if (!this.isPhoneValid(userAdditionalPhoneInline)) {
            this.notify.error("Phone Number is invalid.");
        } else {
            if(userAdditionalPhoneInline.additionalPhoneDto.phoneType != "Cell")
                    userAdditionalPhoneInline.additionalPhoneDto.receiveSMS = false;
                    
            if (userAdditionalPhoneInline.additionalPhoneDto.id == null) {
               // supportItemInLine.supportItems.id = 0;
                userAdditionalPhoneInline.additionalPhoneDto.userId = this.appSession.userId;
                
            }
            this._userAdditionalPhonesServiceProxy.createOrEdit(userAdditionalPhoneInline.additionalPhoneDto).subscribe(() => {
                userAdditionalPhoneInline.rowEdit = false;
                this.notify.info(this.l("SavedSuccessfully"));
                //this.changeDetector.detectChanges();
                this.getPhoneNumbers();
            });            
        }
    }

    isPhoneValid(userAdditionalPhoneInline : UserAdditionalPhoneInline): boolean {
        let regexp = new RegExp('^[0-9]{10}$');
        if (!regexp.test(userAdditionalPhoneInline.additionalPhoneDto.phoneNumber)) 
            return false;
       
        return true;
    }

    phoneNumberRowEdit($event) {
        $event.data.rowEdit = true;
    }

    createPhoneNumber(): void {
        let newItem = new UserAdditionalPhoneInline();
        newItem.rowEdit = true;
        newItem.additionalPhoneDto = new UserAdditionalPhoneDto();
        newItem.additionalPhoneDto.id = null;
        newItem.additionalPhoneDto.phoneType = "Cell";
        newItem.additionalPhoneDto.receiveSMS = true;
        if(!this.primengTableHelper.records)
            this.primengTableHelper.records = [];

        this.primengTableHelper.records.push(newItem);
    }

    deletePhoneNumber(userPhone : UserAdditionalPhoneDto, row : number): void {
        this.message.confirm(this.l("Are you sure you want to delete this Phone Number?"), this.l("AreYouSure"), isConfirmed => {
            if (isConfirmed) {
                if (userPhone.id == null) {
                    this.primengTableHelper.records.splice(row, 1);
                } else {
                    this._userAdditionalPhonesServiceProxy.delete(userPhone.id).subscribe(() => {
                        this.getPhoneNumbers();
                        this.notify.success(this.l("SuccessfullyDeleted"));
                    });
                }
            }
        });
    }

}
