import { Injectable, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CommonService {
    //@Output() samePageClickEvent: EventEmitter<any> = new EventEmitter<any>();

    private messageSource = new BehaviorSubject('defualt message');
    currentMessage = this.messageSource.asObservable();

    constructor() { }

    SamePageClick(pageName: string) {
        this.messageSource.next(pageName);
    }
}
