import { Component, ViewChild, Injector, Output, EventEmitter} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { FAQsServiceProxy, FAQDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as moment from 'moment';
import { Observable } from 'rxjs';

@Component({
    selector: 'faqModal',
    templateUrl: './faq-modal.component.html'
})
export class FAQModalComponent extends AppComponentBase {

    @ViewChild('faqModal', { static: true }) modal: ModalDirective;
    
    active = false; 
    faqList: FAQDto[];
    screenName;

    constructor(
        injector: Injector,
        private _faqServiceProxy: FAQsServiceProxy
        
    ) {
        super(injector);       
    }

    show(screen): void {   
        this.screenName = screen + " FAQ";      
        this._faqServiceProxy.getFAQsForScreen(screen).subscribe(result => {
            this.faqList = result;

            this.active = true;
            this.modal.show();
        });
    } 
   
    close(): void {
        this.active = false;
        this.modal.hide();
    }
}
